import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const CustomMotorSchoolCard = (props) => {
  return (
    <div className="schoolcard custom_card">
      <div className={props.rowType}>
        <div className="col">
          <div className="schoolcard__image custom_img">
            <LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
          </div>
        </div>
        <div className="col">
          <div className="schoolcard__content custom_content">
            <h3>{props.Header}</h3>
            <div className="prices">
              <h5>{props.priceOne}</h5>
              <h5>{props.priceTwo}</h5>
            </div>
            <div>{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomMotorSchoolCard;
